import axios from 'axios';

const API_URL = 'https://deadapi.techysumo.it/backoffice';

export const login = async (username, password) => {
  try {
    let dataPost = {};
    dataPost.username = username;
    dataPost.password = password;
    dataPost.authorization = 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855';
    const response = await axios.post(`${API_URL}/loginUser`, dataPost);
    return response.data;
  } catch (error) {
    throw new Error('Login failed');
  }
};
